<template>
  <div class="highlighted-section-header">
    <navigation-bar
      :background="'transparent'"
      :link="{ name: 'project-home', params: { identifier: projectData.identifier } }"
      :label="projectData.name"
      class="slim"
    />
    <div class="container is-widescreen details">
      <div class="wrapper">
        <div class="columns is-centered">
          <div class="column standard-column details-column">
            <div class="details-column-inner">
              <forum-icon />
              <div class="details-text">
                <h1>
                  {{ title }}
                </h1>
                <p class="description">
                  <slot />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ForumIcon from './icons/ForumIcon.vue'
import NavigationBar from './NavigationBar.vue'

export default {
  name: 'HighlightedSectionHeader',
  components: {
    ForumIcon,
    NavigationBar
  },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('participationProject', ['projectData'])
  }
}
</script>

<style lang="scss" scoped>
.highlighted-section-header {
  background: $blue;
  color: #fff;
  margin-bottom: 24px;

  ::v-deep * {
    color: #fff !important;
  }

  .navigation-bar {
    margin-bottom: 0;
  }

  .details {
    text-align: left;

    svg {
      margin-right: 34px;
      width: 128px;
      margin-top: 10px;
    }

    h1 {
      font-weight: 600;
    }

    .columns {
      margin-top: 0;
    }
  }

  .details-column {
    .details-column-inner {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      display: flex;
    }
  }
}

@include respond(mobile-up) {
  .highlighted-section-header {
    .details {
      h1 {
        font-size: 34px;
        line-height: 42.5px;
      }

      p {
        font-size: 18px;
        line-height: 26px;
      }
    }

    .details-column {
      padding: 0 12px;

      .details-column-inner {
        padding: 23px 40px 27px 0;
      }
    }
  }
}

@include respond(mobile) {
  .highlighted-section-header {
    ::v-deep .navigation-bar {
      .wrapper {
        padding: 0 18px;
      }
    }

    .wrapper {
      padding: 0 18px;
    }

    .details {
      svg {
        display: none;
      }

      h1 {
        font-size: 28px;
        line-height: 35px;
        margin-bottom: 8px;
      }

      p {
        font-size: 16px;
        line-height: 26px;
      }
    }
    .details-column {
      padding: 0 14px;

      .details-column-inner {
        padding: 18px 40px 18px 0;
      }
    }
  }
}
</style>
