<template>
  <!-- eslint-disable -->
  <svg width="95" height="73" viewBox="0 0 95 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.79633 26.6503H54.2458L65.5258 32.242C65.7501 32.3541 65.9924 32.4087 66.2346 32.4087C66.5275 32.4087 66.8189 32.3276 67.0759 32.1687C67.5448 31.8774 67.83 31.3648 67.83 30.8133V26.6503H73.5893C74.4702 26.6503 75.1847 25.9366 75.1847 25.0549V2.55878C75.1847 1.677 74.4702 0.963379 73.5893 0.963379H1.79633C0.915347 0.963379 0.200928 1.677 0.200928 2.55878V25.0549C0.200928 25.9366 0.915347 26.6503 1.79633 26.6503ZM3.39172 4.15418H71.9939V23.4595H66.2346C65.3536 23.4595 64.6392 24.1731 64.6392 25.0549V28.241L55.3286 23.6262C55.1082 23.5156 54.866 23.4595 54.6198 23.4595H3.39172V4.15418Z" fill="currentColor"/>
    <path d="M27.323 72.5333C27.5792 72.6923 27.8715 72.7733 28.1636 72.7733C28.4059 72.7733 28.6489 72.7187 28.8732 72.6066L40.1516 67.0086H92.5979C93.4789 67.0086 94.1933 66.295 94.1933 65.4132V42.925C94.1933 42.0432 93.4789 41.3296 92.5979 41.3296H20.805C19.924 41.3296 19.2096 42.0432 19.2096 42.925V65.4132C19.2096 66.295 19.924 67.0086 20.805 67.0086H26.5681V71.1779C26.5681 71.7294 26.8531 72.2435 27.323 72.5333ZM22.4004 63.8178V44.5204H91.0025V63.8178H39.7776C39.5315 63.8178 39.2885 63.8754 39.068 63.9846L29.7588 68.6056V65.4132C29.7588 64.5315 29.0444 63.8178 28.1634 63.8178H22.4004Z" fill="currentColor"/>
    <path d="M57.827 55.1448C58.2687 54.702 58.4469 54.0533 58.1784 53.4061C57.7666 52.3428 56.3239 52.1328 55.5772 52.8795C54.8882 53.6433 55.0333 54.5996 55.5772 55.1448C56.1664 55.7306 57.1905 55.7812 57.827 55.1448Z" fill="currentColor"/>
    <path d="M68.5002 55.1447C69.1683 54.4748 69.105 53.4812 68.5002 52.8794C67.9049 52.2615 66.8587 52.272 66.2511 52.8794C65.5528 53.6495 65.7154 54.609 66.2511 55.1447C66.8371 55.7305 67.8657 55.779 68.5002 55.1447Z" fill="currentColor"/>
    <path d="M47.1538 55.145C47.8069 54.4903 47.7754 53.4997 47.1538 52.8797C46.5311 52.2559 45.5096 52.2775 44.904 52.8797C44.2133 53.6455 44.362 54.6018 44.904 55.145C45.5408 55.7782 46.5643 55.7345 47.1538 55.145Z" fill="currentColor"/>
    <path d="M34.2309 55.1448C34.8803 55.7924 35.8992 55.7264 36.4808 55.1448C37.1339 54.4901 37.1023 53.4995 36.4808 52.8795C35.8712 52.2699 34.8466 52.2674 34.2309 52.8795C33.5821 53.5252 33.6157 54.5325 34.2309 55.1448Z" fill="currentColor"/>
    <path d="M40.7785 8.19385H12.2086C11.3276 8.19385 10.6132 8.90747 10.6132 9.78925C10.6132 10.671 11.3276 11.3846 12.2086 11.3846H40.7785C41.6595 11.3846 42.3739 10.671 42.3739 9.78925C42.3739 8.90747 41.6596 8.19385 40.7785 8.19385Z" fill="currentColor"/>
    <path d="M29.9519 15.4868H12.2086C11.3276 15.4868 10.6132 16.2004 10.6132 17.0822C10.6132 17.964 11.3276 18.6776 12.2086 18.6776H29.9519C30.8329 18.6776 31.5473 17.964 31.5473 17.0822C31.5473 16.2004 30.8329 15.4868 29.9519 15.4868Z" fill="currentColor"/>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'ForumIcon'
}
</script>
