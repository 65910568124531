<template>
  <participation-view-content-container
    :current-stage="currentStage"
    :grey-background="true"
  >
    <div v-if="projectData && displayForum">
      <highlighted-section-header
        title="¡Te damos la bienvenida al Foro!"
      >
        En este espacio encontrarás temas para continuar debatiendo y aportar valor a esta
        Comunidad con tus opiniones en comentarios.
      </highlighted-section-header>
      <div
        class="container is-widescreen page-bottom-padding"
      >
        <div class="wrapper">
          <div class="columns is-centered">
            <div class="column standard-column">
              <thread-listing
                v-if="projectData"
                :project-identifier="projectData.identifier"
                :detail-route-name="'participant-forum-thread'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </participation-view-content-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import HighlightedSectionHeader from '@/components/HighlightedSectionHeader.vue'
import ParticipationViewContentContainer from '@/components/participation/ParticipationViewContentContainer.vue'
import participationStages from '@/components/participation/participationStages'
import ThreadListing from '@/components/forum/ThreadListing.vue'

export default {
  name: 'ForumHome',
  components: {
    HighlightedSectionHeader,
    ParticipationViewContentContainer,
    ThreadListing
  },
  computed: {
    ...mapGetters('participationProject', ['isCommunityProject']),
    ...mapGetters('forum', ['displayForum']),
    ...mapState('participationParticipant', ['participantData']),
    ...mapState('participationProject', ['projectData'])
  },
  created() {
    this.currentStage = participationStages.forum
  }
}
</script>
